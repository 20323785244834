import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { ActionProduct } from 'types/Action';
import { buildRequestParameters, Filter } from 'utils/requestParameters';

const api = process.env.REACT_APP_API_URL;

export const makeGetActionProductsRequest = (page: number, filters: Filter[], sortValue: string) => {
  const pagination = {
    page: page,
    perPage: 10,
  };
  const parameters = buildRequestParameters({
    filters: filters,
    sort: {
      order: 'desc',
      orderBy: sortValue,
    },
    pagination: pagination,
  });
  return axios.get<Paginate<ActionProduct>>(`${api}/action-products?${parameters}`);
};

export const makePostActionProductRequest = (form: FormData) =>
  axios.post<ActionProduct>(`${api}/action-products`, form);

export const makePutActionProductRequest = (id: number) => (form: FormData) =>
  axios.post<void>(`${api}/action-products/${id}`, form);

export const makeDeleteActionProductRequest = (id: number) => axios.delete<void>(`${api}/action-products/${id}`);
