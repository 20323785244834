export const USER_BANERS = 'USER_BANERS';
export const USER_ACTIVE_ACTIONS = 'USER_ACTIVE_ACTIONS';
export const USER_ACTION = 'USER_ACTION';
export const USER_ACTION_ORDERED = 'USER_ACTION_ORDERED';
export const KAMMT_ACTIVE_SESSION = 'KAMMT_ACTIVE_SESSION';
export const ACTIONS_ORDERS_ARCHIVES = 'ACTIONS_ORDERS_ARCHIVES';
export const ACTIONS_ORDERS = 'ACTIONS_ORDERS';
export const USERS = 'USERS';
export const ACTIONS = 'ACTIONS';
export const USERS_ONLY_DISTRIBUTOR = 'USERS_ONLY_DISTRIBUTOR';
export const USERS_ONLY_KAMMT = 'USERS_ONLY_KAMMT';
export const USERS_FILTERS_OPTIONS = 'USERS_FILTERS_OPTIONS';
export const SESSION_ORDERS_ARCHIVES = 'SESSION_ORDERS_ARCHIVES';
export const SESSION_ORDERS = 'SESSION_ORDERS';
export const PRODUCTS_WAREHOUSE_AVAILABILITY = 'PRODUCTS_WAREHOUSE_AVAILABILITY';
export const ACTIONS_WAREHOUSE_AVAILABILITY = 'ACTIONS_WAREHOUSE_AVAILABILITY';
export const SESSION_ASIGN_ORDER = 'SESSION_ASIGN_ORDER';
export const MY_DISTRIBUTORS = 'MY_DISTRIBUTORS';
export const SESSION_ASIGN_ORDER_ORDERED = 'SESSION_ASIGN_ORDER_ORDERED';
export const ADMIN_BANERS = 'ADMIN_BANERS';
export const MESSAGES = 'MESSAGES';
export const ADMIN_SESSIONS = 'ADMIN_SESSIONS';
export const ACTIONS_PRODUCTS = 'ACTIONS_PRODUCTS';
export const SESSION_PRODUCTS = 'SESSION_PRODUCTS';
export const ALL_SESSIONS = 'ALL_SESSIONS';
export const VOUCHERS = 'VOUCHERS';
export const BUDGETS = 'BUDGETS';
export const ADMIN_DRAWER_NOTIFICATIONS = 'ADMIN_DRAWER_NOTIFICATIONS';
