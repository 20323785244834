import React from 'react';
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import AdminActionProductForm from 'components/AdminProductAction/Form';
import PageHeader from 'components/PageHeader/PageHeader';
import { useSnackbar } from 'notistack';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { makeGetActionAllRequest } from 'core/services/actions';
import { makeDeleteActionProductRequest, makeGetActionProductsRequest } from 'core/services/actionProducts';
import { ActionProduct } from 'types/Action';
import { ACTIONS_PRODUCTS, ALL_SESSIONS, SESSION_PRODUCTS } from 'core/Query';
import Filters from 'components/Admin/OrderingPlatform/ProductsWarehouseAvailability/Filters/Filters';
import { Filter } from 'utils/requestParameters';
import { makeMoveSessionProductRequest } from 'core/services/sessionProducts';
import { IMoveProductRequest, Session } from 'types/Session';
import { makeGetSessionAllRequest } from 'core/services/sessions';

const AdminProductAction = () => {
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [addSession, setAddSession] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('created_at');
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [moveId, setMoveId] = React.useState<number | null>(null);
  const [moveName, setMoveName] = React.useState<string | null>(null);
  const [moveMaxAmount, setMoveMaxAmount] = React.useState<number | string>(0);
  const [moveAmount, setMoveAmount] = React.useState<number | null>(null);
  const [moveDestinationAction, setMoveDestinationAction] = React.useState<Session | undefined>(undefined);
  const sessionQuery = useQuery('sessions-all', makeGetActionAllRequest, {
    select(response) {
      return response.data;
    },
  });
  const marketingQuery = useQuery(ALL_SESSIONS, makeGetSessionAllRequest, {
    select(response) {
      return response.data;
    },
  });
  const dataQuery = useQuery(
    [ACTIONS_PRODUCTS, page, filters, sortValue],
    () => makeGetActionProductsRequest(page, filters, sortValue),
    {
      select(response) {
        return response.data;
      },
      onSuccess(data: Paginate<ActionProduct>) {
        if (data.data.length === 0 && page > 1) {
          setPage((old) => old - 1);
        }
      },
      refetchOnWindowFocus: false,
    },
  );
  const { data, pages } = useCacheQuery({ data: dataQuery.data?.data, total: dataQuery.data?.total });

  const mutatuinDelete = useMutation(makeDeleteActionProductRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ACTIONS_PRODUCTS);
      setDeleteId(null);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.DELETE.ERROR' }), { variant: 'error' });
    },
  });

  const mutatuinMove = useMutation(makeMoveSessionProductRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.MOVE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(SESSION_PRODUCTS);
      setMoveId(null);
      setMoveName(null);
      setMoveAmount(null);
      window.location.reload();
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.MOVE.ERROR' }), { variant: 'error' });
    },
  });

  const confirmDelete = () => {
    if (!deleteId) return;
    mutatuinDelete.mutate(deleteId);
  };

  const confirmMove = () => {
    if (!moveId) return;
    const moveForm: IMoveProductRequest = {
      type: 'action',
      id: moveId,
      amount: moveAmount ? moveAmount : 0,
      destination_id: null != moveDestinationAction ? moveDestinationAction?.id : 0,
    };
    mutatuinMove.mutate(moveForm);
  };

  const handleDelete = (id: number | undefined) => {
    if (!id) return setAddSession(false);
    setDeleteId(id);
  };

  const handleMove = (id: number | undefined, name: string, amount: string | number) => {
    if (!id) return setAddSession(false);
    setMoveName(name);
    setMoveMaxAmount(amount);
    setMoveId(id);
  };

  return (
    <Grid container spacing={2}>
      {dataQuery.isFetching && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[{ value: 'created_at', name: <FormattedMessage id="SORT_BY.CREATED_DATE" /> }]}
      >
        <FormattedMessage id="ADMIN_SESSION_PRODUCT.TITLE" />
      </PageHeader>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" disabled={addSession} variant="contained" onClick={() => setAddSession(true)}>
            <FormattedMessage id="ADMIN_SESSION_PRODUCT.ADD_BUTTON" />
          </Button>
        </Box>
      </Grid>

      <Filters handleChangeFilters={setFilters} />

      {addSession && (
        <AdminActionProductForm
          sessionIsLoading={sessionQuery.isLoading}
          initialValues={{
            id: undefined,
            name: '',
            price: '',
            amount: '',
            shortDescription: '',
            description: '',
            action: undefined,
            selectedUsers: [],
            images: [],
            users: [],
          }}
          handleDelete={handleDelete}
          handleMove={handleMove}
          deleteLoading={mutatuinDelete.isLoading}
          moveLoading={mutatuinMove.isLoading}
          closeAdd={() => setAddSession(false)}
          actions={sessionQuery.data || []}
        />
      )}
      {data?.map((single) => (
        <AdminActionProductForm
          key={single.id}
          sessionIsLoading={sessionQuery.isLoading}
          edit
          initialValues={{
            ...single,
            selectedUsers: single.users.map((user) => ({
              id: user.id,
              name: user.name,
              amount: user.pivot.amount || '',
            })),
          }}
          handleDelete={handleDelete}
          handleMove={handleMove}
          deleteLoading={mutatuinDelete.isLoading}
          moveLoading={mutatuinMove.isLoading}
          actions={sessionQuery.data || []}
        />
      ))}
      {dataQuery.isFetched && data?.length === 0 ? (
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" justifyContent="center" padding={2}>
              <FormattedMessage id="ADMIN_SESSION_PRODUCT.EMPTY" />
            </Box>
          </Paper>
        </Grid>
      ) : null}
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutatuinDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ADMIN_SESSION_PRODUCT.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="ADMIN_SESSION.CONFIRM.DELETE.DESCRIPTION" />
        </DialogContent>
        <DialogActions>
          <Button disabled={mutatuinDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutatuinDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(moveId)}
        onClose={() => {
          if (mutatuinMove.isLoading) return;
          setMoveId(null);
          setMoveName(null);
          setMoveMaxAmount(0);
          setMoveDestinationAction(undefined);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ADMIN_SESSION_PRODUCT.CONFIRM.MOVE" />
          <span>{moveName} ?</span>
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="ADMIN_SESSION_PRODUCT.CONFIRM.MOVE.DESCRIPTION" />
          <Stack spacing={2} sx={{ marginTop: '15px' }}>
            <TextField
              name="moveAmount"
              variant={'outlined'}
              type="number"
              value={moveAmount}
              onChange={(val) => {
                setMoveAmount(+val.target.value);
              }}
              disabled={mutatuinMove.isLoading}
              label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.FORM.AMOUNT" />}
              inputProps={{ min: 0, max: moveMaxAmount }}
              fullWidth
            />
            <Box sx={{ fontSize: '11px', color: 'red', marginTop: '0px !important' }}>
              {null != moveAmount && moveAmount > moveMaxAmount ? (
                <FormattedMessage id="ADMIN_SESSION_PRODUCT.TOO_MANY_VALUE" />
              ) : null}
            </Box>
            <Autocomplete
              disablePortal
              disableClearable
              value={moveDestinationAction}
              options={marketingQuery.data || []}
              loading={marketingQuery.isLoading}
              openOnFocus
              loadingText="Trwa ładowanie"
              noOptionsText="Brak przypisanych użytkowników do akcji promocyjnej"
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                setMoveDestinationAction(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="action"
                  label={<FormattedMessage id="ADMIN_ACTION_PRODUCT_FORM.ACTION" />}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={mutatuinMove.isLoading}
            onClick={() => {
              setMoveId(null);
              setMoveName(null);
              setMoveMaxAmount(0);
              setMoveDestinationAction(undefined);
            }}
          >
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button
            color="secondary"
            disabled={
              mutatuinMove.isLoading ||
              null == moveAmount ||
              moveAmount === 0 ||
              moveAmount > moveMaxAmount ||
              undefined === moveDestinationAction
            }
            variant="contained"
            onClick={confirmMove}
          >
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminProductAction;
