import React from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FilterForm, FiltersProps } from './Filters.types';
import SearchIcon from '@mui/icons-material/Search';

const Filters = (props: FiltersProps) => {
  const { handleChangeFilters } = props;

  const [filters, setFilters] = React.useState<FilterForm>({
    name: '',
  });
  const handleChangeValue = (event: any) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const submitFilters = (values: FilterForm | null = null) => {
    const data = values ? values : filters;

    handleChangeFilters([{ name: 'name', value: data.name }]);
  };

  return (
    <Grid item xs={12}>
      <Paper component="div">
        <Grid container padding={1}>
          <Grid item xs={12} md={12} padding={1}>
            <TextField
              fullWidth
              name="name"
              value={filters.name}
              disabled={false}
              onChange={handleChangeValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setTimeout(submitFilters, 0);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label={<FormattedMessage id="PRODUCTS_WAREHOUSE_AVAILABILITY.FILTER.FORM.PRODUCT.NAME" />}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Filters;
