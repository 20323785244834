import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { SnackbarProvider } from 'notistack';
import MainPageViews from 'views/panel/MainPage/MainPage.views';
import Login from 'views/oauth/Login/Login';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import ForgetPassword from 'views/oauth/ForgetPassword/ForgetPassword';
import ChangePassword from 'views/oauth/ChangePassword/ChangePassword';
import UserProvider from 'context/User/User';
import LocaleProvider from 'context/Locale/Locale';
import DrawerNotificationsProvider from 'context/DrawerNotifications/DrawerNotifications';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

function App() {
  const theme = createTheme({
    palette: { primary: { main: '#747474' }, secondary: { main: '#CF232F' }, background: { default: '#E5E5E5' } },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#fff',
            color: '#000',
            boxShadow: '0 3px 6px 0px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
      <QueryClientProvider client={queryClient}>
        <LocaleProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider maxSnack={3}>
                <Routes>
                  <Route
                    path="/panel/*"
                    element={
                      <UserProvider>
                        <DrawerNotificationsProvider>
                          <MainPageViews />
                        </DrawerNotificationsProvider>
                      </UserProvider>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/:lang" element={<Login />} />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/forget-password/:lang" element={<ForgetPassword />} />
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="/change-password/:lang" element={<ChangePassword />} />
                  <Route path="*" element={<Navigate to="/panel" />} />
                </Routes>
                <Outlet />
              </SnackbarProvider>
            </ThemeProvider>
          </BrowserRouter>
        </LocaleProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
