import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { buildRequestParameters, Filter } from 'utils/requestParameters';
import { BudgetList } from 'types/Butget';

const api = process.env.REACT_APP_API_URL;

export const makeGetSessionBudgetRequest = (page: number, filters: Filter[], sortValue?: string) => {
  const pagination = {
    page: page,
    perPage: 10,
  };
  const parameters = buildRequestParameters({
    filters: filters,
    sort: {
      order: 'desc',
      orderBy: null != sortValue ? sortValue : '',
    },
    pagination: pagination,
  });
  return axios.get<Paginate<BudgetList>>(`${api}/budgets?${parameters}`);
};

export const makeGetDownloadBudgetRequest = () => axios.get(`${api}/export/budgets`, { responseType: 'blob' });
