import PanelLayout from 'components/PanelLayout/PanelLayout';
import { Route, Routes } from 'react-router-dom';
import orderPanelAdminMenuItems from 'core/menu/orderPanelAdminMenuItems';
import AdminSession from './Sessions/Sessions';
import AdminProductSession from './Products/Products';
import AdminProductsWarehouseAvailability from './ProductsWarehouseAvailability/ProductsWarehouseAvailability';
import Users from '../shared/Users/Users';
import Messages from '../shared/Messages/Messages';
import AdminOrders from './Orders/Orders';
import OrderArchives from '../../shared/sessionPanel/OrderArchives/OrderArchives';
import Vouchers from './Vouchers/Vouchers';
import Budgets from './Budgets/Budgets';

const OrderPanel = () => {
  return (
    <Routes>
      <Route
        element={
          <PanelLayout
            linkPath="/"
            disabledMenu={false}
            menu={orderPanelAdminMenuItems}
            title="PLATFORMA DO SKŁADANIA ZAMÓWIEŃ"
          />
        }
      >
        <Route path="/" element={<AdminSession />} />
        <Route path="/warehouse-availability/products" element={<AdminProductsWarehouseAvailability />} />
        <Route path="/products" element={<AdminProductSession />} />
        <Route path="/users" element={<Users />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/orders" element={<AdminOrders />} />
        <Route path="/orders-history" element={<OrderArchives />} />
        <Route path="/vouchers" element={<Vouchers />} />
        <Route path="/budgets" element={<Budgets />} />
      </Route>
    </Routes>
  );
};

export default OrderPanel;
