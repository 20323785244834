import { isString } from 'lodash';

export interface Filter {
  name: string;
  value: string | null | undefined;
}
export interface RequestParameters {
  filters: Filter[];
  sort: {
    order: string;
    orderBy: string;
  } | null;
  pagination: {
    page: number;
    perPage: number;
  } | null;
}
export const buildRequestParameters = (parameters: RequestParameters): string => {
  const { filters, sort, pagination } = parameters;
  const url = new URLSearchParams();

  filters.forEach((filter) => {
    const { name, value } = filter;

    if (!value || !isString(value) || value.length === 0) {
      return;
    }

    url.append(name, value);
  });

  if (sort) {
    url.set('order', sort.order);
    url.set('orderBy', sort.orderBy);
  }

  if (pagination) {
    url.set('page', pagination.page.toString());
    url.set('perPage', pagination.perPage.toString());
  }

  return url.toString();
};
