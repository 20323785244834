import React from 'react';
import { Box, Collapse, Drawer, List, ListItemButton, ListItemText, SxProps, Toolbar, Typography } from '@mui/material';
import { Menu } from 'core/menu/menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DrawerProps } from './Drawer.types';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { useDrawerNotifications } from 'context/DrawerNotifications/DrawerNotifications';
import { AdminDrawerNotifications } from 'types/Drawer';

const MenuCollapse = ({ menu }: { menu: Menu }) => {
  const [open, setOpen] = React.useState<boolean>(true);
  return (
    <>
      <ListItemButton disabled={menu.disabled} onClick={() => setOpen(!open)}>
        <ListItemText>
          <FormattedMessage id={menu.title} values={menu.titleValues} />
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List disablePadding>
          {menu.submenu?.map((inner) => {
            return (
              <MenuItem
                key={`${inner.title}-${inner.titleValues?.session}`}
                menu={inner}
                style={{ pl: 4 }}
                adminNotifications={null}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const NotificationBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -18,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const MenuItem = ({
  menu,
  style,
  adminNotifications,
}: {
  menu: Omit<Menu, 'submenu'>;
  style?: SxProps;
  adminNotifications: AdminDrawerNotifications | undefined | null;
}) => {
  const resolved = useResolvedPath(menu.path);
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();

  const drawNotificationListItem = (badgeContent: number) => {
    return (
      <ListItemButton disabled={menu.disabled} selected={Boolean(match)} onClick={() => navigate(menu.path)} sx={style}>
        <NotificationBadge badgeContent={badgeContent} color="secondary">
          <FormattedMessage id={menu.title} values={menu.titleValues} />
        </NotificationBadge>
      </ListItemButton>
    );
  };

  if (
    menu.title === 'ORDER_PANEL_ADMIN_MENU.ORDERS' &&
    menu.path === '/panel/sessions/orders' &&
    adminNotifications &&
    adminNotifications.productsOrdersCount > 0
  ) {
    return drawNotificationListItem(adminNotifications.productsOrdersCount);
  }

  if (
    menu.title === 'ORDER_PANEL_ADMIN_MENU.ORDERS' &&
    menu.path === '/panel/actions/orders' &&
    adminNotifications &&
    adminNotifications.promotionActionsOrdersCount > 0
  ) {
    return drawNotificationListItem(adminNotifications.promotionActionsOrdersCount);
  }

  if (menu.title === 'ORDER_PANEL_ADMIN_MENU.MESSAGES' && adminNotifications && adminNotifications.messagesCount > 0) {
    return drawNotificationListItem(adminNotifications.messagesCount);
  }

  return (
    <ListItemButton disabled={menu.disabled} selected={Boolean(match)} onClick={() => navigate(menu.path)} sx={style}>
      <FormattedMessage id={menu.title} values={menu.titleValues} />
    </ListItemButton>
  );
};

const MenuComponent = ({ menu, isDesktop, open, onClose }: DrawerProps) => {
  const intl = useIntl();

  const { adminNotifications } = useDrawerNotifications();

  return (
    <Drawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={open}
      onClose={onClose}
      sx={{
        width: 270,
        '& .MuiDrawer-paper': {
          width: 270,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <List sx={{ flex: 1 }}>
        {menu.map((single) => {
          if (single.submenu) {
            return <MenuCollapse key={single.title} menu={single} />;
          }

          return <MenuItem key={single.title} menu={single} adminNotifications={adminNotifications} />;
        })}
      </List>
      <Box padding={1}>
        <Typography variant="subtitle2" color="textSecondary">
          <FormattedMessage id="APP_BAR.MENU.HELP.TITLE" />
        </Typography>
        <Typography
          component="a"
          href={`mailto:${intl.formatMessage({ id: 'APP_BAR.MENU.HELP.CONTENT' })}`}
          variant="body1"
          color="textPrimary"
        >
          <FormattedMessage id="APP_BAR.MENU.HELP.CONTENT" />
        </Typography>
      </Box>
    </Drawer>
  );
};

export default MenuComponent;
