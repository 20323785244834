import { Menu } from './menu';

const orderPanelAdminMenuItems: Menu[] = [
  {
    title: 'ORDER_PANEL_ADMIN_MENU.SESSIONS',
    path: '/panel/sessions/',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS',
    path: '/panel/sessions/orders',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.PRODUCTS',
    path: '/panel/sessions/products',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.WAREHOUSE_AVAILABILITY.PRODUCTS',
    path: '/panel/sessions/warehouse-availability/products',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.USERS',
    path: '/panel/sessions/users',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS_HISTORY',
    path: '/panel/sessions/orders-history',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.MESSAGES',
    path: '/panel/sessions/messages',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.VOUCHERS',
    path: '/panel/sessions/vouchers',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.BUDGETS',
    path: '/panel/sessions/budgets',
  },
];

export default orderPanelAdminMenuItems;
