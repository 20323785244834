import axios from 'core/clients/api';
import { OrderList, OrderStatus, SendOrder } from 'types/Order';
import { Paginate } from 'types/Paginate';
import { buildRequestParameters, Filter } from 'utils/requestParameters';

const api = process.env.REACT_APP_API_URL;

export const makePostSessionOrderRequest = (id: string) => (orders: SendOrder[]) =>
  axios.post<void>(`${api}/sessions/${id}/orders`, { orders });

export const makeGetSessionOrderRequest = (page: number, filters: Filter[], sortValue: string) => {
  const pagination = {
    page: page,
    perPage: 10,
  };
  const parameters = buildRequestParameters({
    filters: filters,
    sort: {
      order: 'desc',
      orderBy: sortValue,
    },
    pagination: pagination,
  });
  return axios.get<Paginate<OrderList>>(`${api}/sesions-orders?${parameters}`);
};

export const makeDeleteSessionOrderRequest = (id: number) => axios.delete<void>(`${api}/sesions-orders/${id}`);

export const makeDeleteSessionOrderProductRequest = (params: { orderSession: number; id: number }) =>
  axios.delete<void>(`${api}/sesions-orders/${params.orderSession}/product/${params.id}`);

export const makePostSessionOrderChangeStatusRequest = (params: { orderSession: number; status: OrderStatus }) =>
  axios.post<void>(`${api}/sesions-orders/${params.orderSession}/change-status`, { status: params.status });
