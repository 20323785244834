import React from 'react';
import { useQuery } from 'react-query';
import { ADMIN_DRAWER_NOTIFICATIONS } from 'core/Query';
import { makeGetAdminDrawerNotificationsRequest } from 'core/services/drawerNotifications';
import { useUser } from '../User/User';
import { UserRole } from 'types/Users';
import { AdminDrawerNotifications } from 'types/Drawer';

const DrawerNotifications = React.createContext<{
  adminNotifications: AdminDrawerNotifications | undefined;
  reloadAdminNotifications: () => void;
}>({
  adminNotifications: undefined,
  reloadAdminNotifications: () => {
    return;
  },
});

export const useDrawerNotifications = () => {
  const drawerNotifications = React.useContext(DrawerNotifications);
  if (!drawerNotifications) throw new Error('use hook with context');

  return drawerNotifications;
};

const DrawerNotificationsProvider: React.FunctionComponent = ({ children }) => {
  const user = useUser();
  const adminNotificationsQuery = useQuery([ADMIN_DRAWER_NOTIFICATIONS], makeGetAdminDrawerNotificationsRequest, {
    select(response) {
      return response.data;
    },
  });

  const reload = () => {
    if (!user || user.role.id !== UserRole.AMIN) {
      return;
    }

    adminNotificationsQuery.refetch();
  };

  return (
    <DrawerNotifications.Provider
      value={{ adminNotifications: adminNotificationsQuery?.data, reloadAdminNotifications: reload }}
    >
      {children}
    </DrawerNotifications.Provider>
  );
};

export default DrawerNotificationsProvider;
