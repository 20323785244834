import axios from 'core/clients/api';
import { buildRequestParameters, Filter } from 'utils/requestParameters';

const api = process.env.REACT_APP_API_URL;

export const makeGetProductsWarehouseAvailabilityRequest = (filters: Filter[]) => {
  const parameters = buildRequestParameters({
    filters: filters,
    sort: null,
    pagination: null,
  });

  return axios.get<any>(`${api}/admin/warehouse-availability/products?${parameters}`);
};

export const makeGetActionsWarehouseAvailabilityRequest = (filters: Filter[]) => {
  const parameters = buildRequestParameters({
    filters: filters,
    sort: null,
    pagination: null,
  });

  return axios.get<any>(`${api}/admin/warehouse-availability/actions?${parameters}`);
};

export const makeGetDownloadProductsWarehouseAvailabilityRequest = () =>
  axios.get(`${api}/admin/warehouse-availability/products/excel`, { responseType: 'blob' });

export const makeGetDownloadActionsWarehouseAvailabilityRequest = () =>
  axios.get(`${api}/admin/warehouse-availability/actions/excel`, { responseType: 'blob' });
