import React from 'react';
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  IconButton,
  Typography,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import fileDownload from 'js-file-download';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { useSnackbar } from 'notistack';
import {
  makeDeleteSessionOrderProductRequest,
  makeDeleteSessionOrderRequest,
  makeGetSessionOrderRequest,
  makePostSessionOrderChangeStatusRequest,
} from 'core/services/sessionOrders';
import { OrderList, OrderStatus } from 'types/Order';
import { format, parseISO } from 'date-fns';
import formatStatus from 'utils/formatStatus';
import { isBackendError } from 'utils/backendError';
import { SESSION_ORDERS, USERS_ONLY_KAMMT } from 'core/Query';
import formatAddress from 'utils/formatAddress';
import { makeGetDownloadSessionsRequest } from 'core/services/sessions';
import { useDrawerNotifications } from 'context/DrawerNotifications/DrawerNotifications';
import Filters from 'components/Admin/OrderingPlatform/ProductsWarehouseAvailability/Filters/Filters';
import { Filter } from 'utils/requestParameters';

const AdminOrders = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { reloadAdminNotifications } = useDrawerNotifications();
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const [deleteProduct, setDeleteProduct] = React.useState<{ id: number; orderSession: number } | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('id');
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const orderQuery = useQuery(
    [SESSION_ORDERS, page, filters, sortValue],
    () => makeGetSessionOrderRequest(page, filters, sortValue),
    {
      select(response): Paginate<OrderList> {
        return response.data;
      },
      onSuccess(data: Paginate<OrderList>) {
        reloadAdminNotifications();
        if (data.data.length === 0 && page > 1) {
          setPage((old) => old - 1);
        }
      },
    },
  );
  const { data, pages } = useCacheQuery({ data: orderQuery.data?.data, total: orderQuery.data?.total });
  const mutationDelete = useMutation(makeDeleteSessionOrderRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([SESSION_ORDERS, page, sortValue]);
      queryClient.refetchQueries([USERS_ONLY_KAMMT]);
      reloadAdminNotifications();
      setDeleteId(null);
    },
    onError(error) {
      if (isBackendError(error)) {
        if (error.response?.status === 400) {
          enqueueSnackbar(error.response.data, { variant: 'error' });
        }
        return;
      }
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.DELETE.ERROR' }), { variant: 'error' });
    },
  });
  const mutationProductDelete = useMutation(makeDeleteSessionOrderProductRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.PRODUCT.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([SESSION_ORDERS, page, sortValue]);
      queryClient.refetchQueries([USERS_ONLY_KAMMT]);
      setDeleteProduct(null);
    },
    onError(error) {
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.PRODUCT.DELETE.ERROR' }), { variant: 'error' });
    },
  });
  const mutationChangeStatus = useMutation(makePostSessionOrderChangeStatusRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ORDER.CHANGE_STATUS.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([SESSION_ORDERS, page, sortValue]);
      reloadAdminNotifications();
    },
    onError() {
      queryClient.refetchQueries([SESSION_ORDERS, page, sortValue]);
      enqueueSnackbar(intl.formatMessage({ id: 'ORDER.CHANGE_STATUS.ERROR' }), { variant: 'error' });
    },
  });
  const downloadReportMutation = useMutation(makeGetDownloadSessionsRequest, {
    onSuccess(response) {
      fileDownload(response.data, 'report.xlsx');
    },
  });
  const confirmDelete = () => {
    if (!deleteId) return;
    mutationDelete.mutate(deleteId);
  };
  const handleDelete = (id: number) => {
    setDeleteId(id);
  };
  const confirmDeleteProduct = () => {
    if (!deleteProduct) return;
    mutationProductDelete.mutate(deleteProduct);
  };
  const handleChangeStatus = (event: SelectChangeEvent<OrderStatus>, id: number) => {
    mutationChangeStatus.mutate({ orderSession: id, status: event.target.value as OrderStatus });
  };
  return (
    <Grid container spacing={2}>
      {(orderQuery.isLoading || orderQuery.isRefetching) && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[{ value: 'id', name: <FormattedMessage id="SORT_BY.ORDER_NO" /> }]}
      >
        <FormattedMessage id="KAM_MT.ORDER.TITLE" />
      </PageHeader>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            color="secondary"
            disabled={downloadReportMutation.isLoading}
            variant="contained"
            onClick={() => downloadReportMutation.mutate()}
          >
            <FormattedMessage id="DOWNLOAD.ORDERS" />
          </Button>
        </Box>
      </Grid>

      <Filters handleChangeFilters={setFilters} />

      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTION_ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.PRODUCTS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.PRICE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.COMPANY" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ADDRESS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.KAM" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.STATUS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.DATE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTIONS" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={single.id}>
                    <TableCell>{single.session?.name}</TableCell>
                    <TableCell>{single.id}</TableCell>
                    <TableCell>
                      {single.products.map((product) => (
                        <Stack key={product.name} spacing={1} direction="row" alignItems="center">
                          <Typography variant="body2">
                            {product.name} - {product.pivot.amount} szt.
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => setDeleteProduct({ id: product.id, orderSession: single.id })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                    </TableCell>
                    <TableCell>
                      {single.products.reduce((old, curr) => {
                        const value = curr.price * curr.pivot.amount;
                        return old + value;
                      }, 0)}{' '}
                      pt
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributor.company}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributor.name}</Typography>
                      <Typography variant="body2">
                        {single.distributor.street}{' '}
                        {formatAddress(single.distributor.houseNumber, single.distributor.flatNumber)}
                      </Typography>
                      <Typography variant="body2">
                        {single.distributor.zipCode} {single.distributor.city}
                      </Typography>
                      <Typography variant="body2">{single.distributor.phone}</Typography>
                      <Typography variant="body2">{single.distributor.email}</Typography>
                      {single.details && (
                        <>
                          <hr />
                          <Typography variant="body2">{single.details}</Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.kammt.company}</Typography>
                      <Typography variant="body2">{single.kammt.name}</Typography>
                      <Typography variant="body2">{single.kammt.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Select
                        size="small"
                        defaultValue={single.status}
                        onChange={(event) => handleChangeStatus(event, single.id)}
                      >
                        <MenuItem disabled value={OrderStatus.CREATED}>
                          {formatStatus(OrderStatus.CREATED)}
                        </MenuItem>
                        <MenuItem value={OrderStatus.DURING}>{formatStatus(OrderStatus.DURING)}</MenuItem>
                        <MenuItem value={OrderStatus.FINISH}>{formatStatus(OrderStatus.FINISH)}</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>{format(parseISO(single.createdAt), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(single.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {orderQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="KAM_MT.ORDERS.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutationDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ORDER.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="ORDER.CONFIRM.DELETE.DESCRIPTION" />
        </DialogContent>
        <DialogActions>
          <Button disabled={mutationDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutationDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(deleteProduct)}
        onClose={() => {
          if (mutationProductDelete.isLoading) return;
          setDeleteProduct(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ORDER.PRODUCT.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogActions>
          <Button disabled={mutationDelete.isLoading} onClick={() => setDeleteProduct(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button
            color="secondary"
            disabled={mutationDelete.isLoading}
            variant="contained"
            onClick={confirmDeleteProduct}
          >
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminOrders;
