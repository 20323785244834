import React from 'react';
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { useSnackbar } from 'notistack';
import { makeDeleteSessionOrderRequest, makeGetSessionOrderRequest } from 'core/services/sessionOrders';
import { OrderList, OrderStatus } from 'types/Order';
import { format, parseISO } from 'date-fns';
import formatStatus from 'utils/formatStatus';
import { isBackendError } from 'utils/backendError';
import { KAMMT_ACTIVE_SESSION, SESSION_ORDERS } from 'core/Query';
import formatAddress from 'utils/formatAddress';
import { Filter } from 'utils/requestParameters';
import Filters from 'components/Admin/OrderingPlatform/ProductsWarehouseAvailability/Filters/Filters';

const Orders = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('id');
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const orderQuery = useQuery(
    [SESSION_ORDERS, page, filters, sortValue],
    () => makeGetSessionOrderRequest(page, filters, sortValue),
    {
      select(response): Paginate<OrderList> {
        return response.data;
      },
      onSuccess(data: Paginate<OrderList>) {
        if (data.data.length === 0 && page > 1) {
          setPage((old) => old - 1);
        }
      },
    },
  );
  const { data, pages } = useCacheQuery({ data: orderQuery.data?.data, total: orderQuery.data?.total });
  const mutatuinDelete = useMutation(makeDeleteSessionOrderRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([SESSION_ORDERS, page, sortValue]);
      queryClient.refetchQueries([KAMMT_ACTIVE_SESSION]);
      setDeleteId(null);
    },
    onError(error) {
      if (isBackendError(error)) {
        if (error.response?.status === 400) {
          enqueueSnackbar(error.response.data, { variant: 'error' });
        }
        return;
      }
      enqueueSnackbar(intl.formatMessage({ id: 'KAM_MT.ORDER.DELETE.ERROR' }), { variant: 'error' });
    },
  });
  const confirmDelete = () => {
    if (!deleteId) return;
    mutatuinDelete.mutate(deleteId);
  };
  const handleDelete = (id: number) => {
    setDeleteId(id);
  };

  return (
    <Grid container spacing={2}>
      {(orderQuery.isLoading || orderQuery.isRefetching) && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[{ value: 'id', name: <FormattedMessage id="SORT_BY.ORDER_NO" /> }]}
      >
        <FormattedMessage id="KAM_MT.ORDER.TITLE" />
      </PageHeader>

      <Filters handleChangeFilters={setFilters} />

      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTION_ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.PRODUCTS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.PRICE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.COMPANY" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ADDRESS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.KAM" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.STATUS" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.DATE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTIONS" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={single.id}>
                    <TableCell>{single.session?.name}</TableCell>
                    <TableCell>{single.id}</TableCell>
                    <TableCell>
                      {single.products.map((product) => (
                        <Typography key={product.name} variant="body2">
                          {product.name} - {product.pivot.amount} szt.
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      {single.products.reduce((old, curr) => {
                        const value = curr.price * curr.pivot.amount;
                        return old + value;
                      }, 0)}{' '}
                      pt
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributor.company}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.distributor.name}</Typography>
                      <Typography variant="body2">
                        {single.distributor.street}{' '}
                        {formatAddress(single.distributor.houseNumber, single.distributor.flatNumber)}
                      </Typography>
                      <Typography variant="body2">
                        {single.distributor.zipCode} {single.distributor.city}
                      </Typography>
                      <Typography variant="body2">{single.distributor.phone}</Typography>
                      <Typography variant="body2">{single.distributor.email}</Typography>
                      {single.details && (
                        <>
                          <hr />
                          <Typography variant="body2">{single.details}</Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{single.kammt.company}</Typography>
                      <Typography variant="body2">{single.kammt.name}</Typography>
                      <Typography variant="body2">{single.kammt.email}</Typography>
                    </TableCell>
                    <TableCell>{formatStatus(single.status)}</TableCell>
                    <TableCell>{format(parseISO(single.createdAt), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>
                      {single.status === OrderStatus.CREATED && (
                        <>
                          <IconButton onClick={() => handleDelete(single.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {orderQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="COMMON.ORDERS.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutatuinDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="ORDER.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="ORDER.CONFIRM.DELETE.DESCRIPTION" />
        </DialogContent>
        <DialogActions>
          <Button disabled={mutatuinDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutatuinDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Orders;
