import { User } from './Users';

export interface Order {
  id?: number;
  userId: number;
  product: {
    availableProductValue?: boolean;
    amount?: number;
    id: number;
    name: string;
    chooseAmount: number;
    price: number;
  };
}

export interface SendOrder {
  userId: string;
  details?: string;
  products: {
    id: number;
    amount: number;
  }[];
}

export enum OrderStatus {
  CREATED = 'created',
  DURING = 'during',
  FINISH = 'finish',
}

export interface IOrderDistributor {
  id: number;
  name: string;
  email: string;
  email_verified_at: string | null;
  company: string;
  phone: string;
  street: string;
  house_number: string;
  flat_number: string;
  zip_code: string;
  city: string;
  user_id: number | null;
  created_at: string;
  updated_at: string;
  role_id: number;
  lang: string;
}

export interface OrderList {
  id: number;
  details: string | null;
  sessionId?: number;
  actionId?: number;
  status: OrderStatus;
  createdAt: string;
  session?: {
    name: string;
  };
  action?: {
    name: string;
  };
  products: {
    id: number;
    name: string;
    price: number;
    pivot: {
      amount: number;
    };
  }[];
  distributor: User;
  kammt: IOrderDistributor;
}

export interface OrderListArchive {
  id: number;
  details: string | null;
  orderId: number;
  sessionId?: number;
  sessionName?: string;
  actionId?: number;
  actionName?: string;
  status: OrderStatus;
  orderDate: string;
  products: {
    id: number;
    name: string;
    price: number;
    amount: number;
  }[];
  price: number;
  distributorCompany: string;
  distributorStreet: string;
  distributorHouseNumber: string;
  distributorFlatNumber?: string;
  distributorZipCode: string;
  distributorCity: string;
  distributorName: string;
  distributorPhone: string;
  distributorEmail: string;
}
