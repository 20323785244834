import React from 'react';
import { Grid, Stack, Typography, Button, Paper, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import groupBy from 'lodash/groupBy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import 'react-perfect-scrollbar/dist/css/styles.css';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import Product from 'components/Product/Product';
import ProductDrop from 'components/Product/ProductDrop';
import { Order, SendOrder } from 'types/Order';
import { makeGetActionRequest, makeGetOrderedActionRequest, makePostActionOrderRequest } from 'core/services/actions';
import UserCart from 'components/UserCart/UserCart';
import useTimer from 'hooks/useTimer';
import { USER_ACTION, USER_ACTION_ORDERED, USER_ACTIVE_ACTIONS } from 'core/Query';
import useGlobalErrors from 'hooks/useGlobalErrors/useGlobalErrors';
import { isBackendError } from 'utils/backendError';

const Action = () => {
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [details, setDetails] = React.useState<string>('');
  const [cartBalance, setCartBalance] = React.useState(0);
  const { globalFormatErrors, setErrors } = useGlobalErrors();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const sessionQuery = useQuery([USER_ACTION, id], () => makeGetActionRequest(id as string), {
    select(response) {
      return response.data;
    },
    onError() {
      setErrors(['USER.ACTION.NOT_FOUND']);
    },
  });
  const balance = null != sessionQuery.data ? sessionQuery.data.users[0].pivot.balance : 0;
  const showBalance = balance - cartBalance;

  const time = useTimer({ endDate: sessionQuery.data?.endDate });
  const addMutation = useMutation(makePostActionOrderRequest(id as string), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'USER.ACTION.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.resetQueries([USER_ACTIVE_ACTIONS]);
      navigate('/panel/orders');
    },
    onError(error: unknown) {
      if (isBackendError(error)) {
        const statusIs400 = error.response?.status === 400;
        if (statusIs400) {
          enqueueSnackbar(intl.formatMessage({ id: `USER.ACTION.ADD.ERROR.${error.response?.data.toUpperCase()}` }), {
            variant: 'error',
          });
          return;
        }
      }
      enqueueSnackbar(intl.formatMessage({ id: 'USER.ACTION.ADD.ERROR' }), { variant: 'error' });
    },
  });
  React.useEffect(() => {
    setErrors([]);
  }, [id, setErrors]);
  const handleSend = () => {
    const sendOrders: SendOrder[] = [];
    let summary = 0;
    Object.entries(groupBy(orders, 'userId')).forEach(([key, orders]) => {
      sendOrders.push({
        userId: key,
        details,
        products: orders.map((innerOrder) => {
          summary += innerOrder.product.price * innerOrder.product.chooseAmount;
          return {
            id: innerOrder.product.id,
            amount: innerOrder.product.chooseAmount,
          };
        }),
      });
    });
    if (balance && summary > balance) {
      setErrors(['USER.ACTION.BALANCE_NOT_ENOUGH']);
      return;
    }
    addMutation.mutate(sendOrders[0]);
  };

  const handleAddOrder = (order: Order) => {
    const productPrice = order.product.price * order.product.chooseAmount;
    setCartBalance((old) => old + productPrice);
    setOrders((old) => {
      const orderExist = old.find((single) => single.userId === order.userId && single.product.id === order.product.id);
      if (orderExist) {
        const oldFilter = old.filter(
          (single) => single.userId !== order.userId || single.product.id !== order.product.id,
        );
        const addOrder: Order = {
          ...orderExist,
          product: {
            ...orderExist.product,
            chooseAmount: orderExist.product.chooseAmount + order.product.chooseAmount,
            availableProductValue: order.product.availableProductValue || showBalance < order.product.price,
          },
        };
        return [...oldFilter, addOrder];
      }
      return [...old, order];
    });
  };

  const handleRemoveOrder = (order: Order) => {
    const totalProductPrice = order.product.price * order.product.chooseAmount;
    setCartBalance((old) => old - totalProductPrice);
    setErrors([]);
    setOrders((old) => {
      const oldFilter = old.filter(
        (single) => single.userId !== order.userId || single.product.id !== order.product.id,
      );
      return oldFilter;
    });
  };

  const handleOrderEmit = (value: Order) => {
    handleAddOrder(value);
  };

  const handleAddOneMoreItem = (order: Order) => {
    const productPrice = order.product.price;
    setCartBalance((old) => old + productPrice);

    const currentFilter = orders.find((elem) => elem.userId === order.userId && elem.product.id === order.product.id);
    if (currentFilter) {
      currentFilter.product.chooseAmount += 1;
    }
  };
  const handleRemoveOneItem = (order: Order) => {
    const productPrice = order.product.price;
    setCartBalance((old) => old - productPrice);

    const currentFilter = orders.find((elem) => elem.userId === order.userId && elem.product.id === order.product.id);
    if (currentFilter) {
      if (currentFilter.product.chooseAmount > 1) {
        currentFilter.product.chooseAmount -= 1;
      } else {
        orders.splice(orders.indexOf(currentFilter), 1);
      }
    }
  };

  const orderedQuery = useQuery([USER_ACTION_ORDERED, id], () => makeGetOrderedActionRequest(id as string), {
    select(response) {
      return response.data;
    },
    onError() {
      setErrors(['USER.ACTION.NOT_FOUND']);
    },
  });

  const action = sessionQuery.isFetched && !sessionQuery.data;
  const ordered = orderedQuery.isFetched && !orderedQuery.data;
  if (action || ordered) return globalFormatErrors;
  const isLoading = sessionQuery.isLoading || orderedQuery.isLoading;
  return (
    <DndProvider backend={HTML5Backend}>
      {isLoading && <FullPageLoader />}
      {!isLoading && (
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <PageHeaderNoSort>
              <FormattedMessage id="USER.ACTION.TITLE" /> {time}
            </PageHeaderNoSort>
          </Grid>
          {sessionQuery.data && (
            <>
              <Typography variant="h6">
                <FormattedMessage id="PANEL_USERS_MENU.ACTION" values={{ action: sessionQuery.data?.name }} />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="USER.ACTION.BALANCE" values={{ balance: showBalance }} />
              </Typography>
            </>
          )}

          <PerfectScrollbar>
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              {sessionQuery.data?.userProducts.map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  chooseAmount={orders
                    .filter((order) => order.product.id === product.id)
                    .reduce((old, curr) => {
                      return old + curr.product.chooseAmount;
                    }, 0)}
                  orderedAmount={{
                    all: (orderedQuery.data && orderedQuery.data[product.id] && orderedQuery.data[product.id].all) ?? 0,
                    user:
                      (orderedQuery.data && orderedQuery.data[product.id] && orderedQuery.data[product.id].user) ?? 0,
                  }}
                  onEmit={handleOrderEmit}
                  balance={showBalance}
                  orders={orders}
                />
              ))}
            </Stack>
          </PerfectScrollbar>
          <Paper sx={{ padding: 2 }}>
            <Typography>
              <FormattedMessage id="USER.ACTION.INSTRUCTION" />
            </Typography>
          </Paper>
          <UserCart
            id={1}
            orders={orders}
            dragProduct={handleAddOrder}
            removeProduct={handleRemoveOrder}
            addOneMoreItem={handleAddOneMoreItem}
            removeOneItem={handleRemoveOneItem}
            showBalance={showBalance}
          />
          <Paper sx={{ padding: 2 }}>
            <TextField
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              label={<FormattedMessage id="USER.ACTION.ORDER.DETAIL" />}
              inputProps={{ maxLength: 255 }}
              multiline
              fullWidth
            />
          </Paper>
          <ProductDrop />
          <Grid container spacing={2}>
            {globalFormatErrors}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Typography variant="h6">
                  <FormattedMessage id="COMMON.ORDER.SUFFIX" />
                </Typography>
                <Button color="secondary" disabled={orders.length === 0} variant="contained" onClick={handleSend}>
                  <FormattedMessage id="COMMON.ORDER.BUTTON" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
    </DndProvider>
  );
};

export default Action;
