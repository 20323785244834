import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { IMoveProductRequest, Session, SessionProduct } from 'types/Session';
import { buildRequestParameters, Filter } from 'utils/requestParameters';

const api = process.env.REACT_APP_API_URL;

export const makeGetSessionProductsRequest = (page: number, filters: Filter[], sortValue: string) => {
  const pagination = {
    page: page,
    perPage: 10,
  };
  const parameters = buildRequestParameters({
    filters: filters,
    sort: {
      order: 'desc',
      orderBy: sortValue,
    },
    pagination: pagination,
  });
  return axios.get<Paginate<SessionProduct>>(`${api}/session-products?${parameters}`);
};

export const makePostSessionProductRequest = (form: FormData) => axios.post<Session>(`${api}/session-products`, form);

export const makePutSessionProductRequest = (id: number) => (form: FormData) =>
  axios.post<void>(`${api}/session-products/${id}`, form);

export const makeDeleteSessionProductRequest = (id: number) => axios.delete<void>(`${api}/session-products/${id}`);

export const makeMoveSessionProductRequest = (form: IMoveProductRequest) =>
  axios.post<IMoveProductRequest>(`${api}/products/move`, {
    type: form.type,
    id: form.id,
    amount: form.amount,
    destination_id: form.destination_id,
  });
