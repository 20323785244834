import React from 'react';
import clsx from 'clsx';
import {
  List,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  CircularProgress,
  Box,
  FormHelperText,
  TextField,
} from '@mui/material';
import xorBy from 'lodash/xorBy';
import isEqual from 'lodash/isEqual';
import { Fieldset, Legend } from './SelectList.styles';
import { SelectListProps } from './SelectList.types';
import { BaseType } from './SelectList.types';

const SelectList = <T extends BaseType>(props: SelectListProps<T>) => {
  const {
    title,
    secondTitle,
    secondTitleSession,
    error,
    optionsIsLoading,
    options,
    selectedOptions,
    emptyMessage,
    disabled,
    onChange,
    allMessage,
    height = 227,
  } = props;
  const checked = (id: number) => Boolean(selectedOptions.find((single) => single.id === id));
  const findValue = (id: number, innerFieldText: string) => {
    const element = selectedOptions.find((single) => single.id === id);
    if (!element) return '';
    return element[innerFieldText];
  };
  const hanldeSetValue = (
    id: number,
    name: string,
    innerFieldName: string,
    value: string,
    setValue: any,
    field: string,
  ) => {
    const newValue = xorBy(selectedOptions, [{ id }] as any, 'id');
    setValue(field, [...newValue, { id, name, [innerFieldName]: value }]);
  };
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {secondTitle && (
          <Box
            sx={{
              position: 'absolute',
              top: `${secondTitleSession ? '0' : '-11px'}`,
              right: `${secondTitleSession ? '87px' : '43px'}`,
              fontSize: '12px',
              color: '#00000099',
              backgroundColor: '#fff',
              padding: '0 5px',
            }}
          >
            {secondTitle}
          </Box>
        )}
        <Fieldset style={{ height }} className={clsx({ error: Boolean(error) })}>
          <Legend style={{ marginRight: 8 }} className={clsx({ error: Boolean(error) })}>
            {title}
          </Legend>
          <List dense disablePadding>
            {optionsIsLoading && (
              <ListItem key="loading">
                <Box padding={2}>
                  <CircularProgress color="secondary" />
                </Box>
              </ListItem>
            )}
            {!optionsIsLoading && options.length === 0 && <ListItem key="no-row">{emptyMessage}</ListItem>}
            {!optionsIsLoading && options.length > 0 && (
              <>
                <ListItem key="all">
                  <ListItemIcon>
                    <Checkbox
                      disabled={disabled}
                      checked={selectedOptions.length === options.length}
                      onChange={(_, value) => {
                        if (value) {
                          onChange(options);
                          return;
                        }
                        onChange([]);
                      }}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText>{allMessage}</ListItemText>
                </ListItem>
                {options.map((option, index) => (
                  <ListItem key={option.id}>
                    <ListItemIcon>
                      <Checkbox
                        disabled={disabled}
                        checked={checked(option.id)}
                        onChange={() => onChange(xorBy(selectedOptions, [option], 'id'))}
                        size="small"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      {option.company} - {option.name}
                    </ListItemText>
                    {checked(option.id) && props.type === 'fied' && (
                      <TextField
                        type="number"
                        value={findValue(option.id, props.innerFieldName)}
                        size="small"
                        sx={{ width: 100 }}
                        onChange={(event) =>
                          hanldeSetValue(
                            option.id,
                            option.name,
                            props.innerFieldName,
                            event.target.value,
                            props.inputSetValue,
                            props.fieldName,
                          )
                        }
                        onBlur={props.inputHandleBlur}
                      />
                    )}
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Fieldset>
        {Boolean(error) && (
          <FormHelperText style={{ marginTop: 0 }} error>
            {error}
          </FormHelperText>
        )}
      </Box>
    </>
  );
};

const propsAreEqual = <T extends BaseType>(prevProps: SelectListProps<T>, nextProps: SelectListProps<T>) => {
  const prevError = (prevProps as any).errors;
  const nextError = (prevProps as any).errors;
  return (
    prevProps.error === nextProps.error &&
    prevProps.optionsIsLoading === nextProps.optionsIsLoading &&
    prevProps.disabled === nextProps.disabled &&
    isEqual(prevProps.options, nextProps.options) &&
    isEqual(prevProps.selectedOptions, nextProps.selectedOptions) &&
    isEqual(prevError, nextError)
  );
};

export default React.memo(SelectList, propsAreEqual);
