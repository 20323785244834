import { Me, User, UserFilterOptions, UserSelectedFilters } from 'types/Users';
import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { UserForm } from 'components/Users/Form.types';
import { Filter } from 'utils/requestParameters';

const api = process.env.REACT_APP_API_URL;

export const makeGetMeRequest = () => axios.get<Me>(`${api}/me`);

export const makePostLogoutRequest = () => axios.post<void>(`${api}/logout`);

export const makeGetUsersKamMtRequest = () =>
  axios.get<Paginate<User>>(`${api}/users?only=2&perPage=10000&orderBy=company&order=asc`);

export const makeGetUsersDistributorRequest = () => axios.get<Paginate<User>>(`${api}/users?only=3&perPage=10000`);

export const makeGetUsersRequest = (
  page: number,
  sortValue: string,
  filters: UserSelectedFilters,
  searchFilters: Filter[],
) => {
  let url = `${api}/users?page=${page}&perPage=10&orderBy=${sortValue}&order=desc`;
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      url += `&${key}=${value}`;
    }
  });
  if (searchFilters.length > 0 && null != searchFilters[0].value) {
    url += `&name=${searchFilters[0].value}`;
  }
  return axios.get<Paginate<User>>(url);
};

export const makeDeleteUserRequest = (id: number) => axios.delete<void>(`${api}/users/${id}`);

export const makePostUserRequest = (user: UserForm) => axios.post<User>(`${api}/users`, user);

export const makePutUserRequest = (id: number) => (user: UserForm) => axios.put<User>(`${api}/users/${id}`, user);

export const makeGetMyDistributorRequest = (sessionId: string | number) =>
  axios.get<User[]>(`${api}/sessions/${sessionId}/my-distributors`);

export const makeGetOrderedRequest = (sessionId: string) =>
  axios.get<Record<string, { all?: number; user?: number }>>(`${api}/sessions/${sessionId}/ordered`);

export const makeGetUserFilterOptionsRequest = () => axios.get<UserFilterOptions>(`${api}/users/filter-options`);
