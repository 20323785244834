import { List, ListItem, ListItemText, Paper, IconButton, Typography, Tooltip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useDrop } from 'react-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { CartProps } from './Cart.types';
import { FormattedMessage } from 'react-intl';

const Cart = (props: CartProps) => {
  const {
    orders,
    dragProduct,
    removeProduct,
    fullWidth,
    disabledDetails,
    details,
    setDetails,
    addOneMoreItem,
    removeOneItem,
  } = props;
  const summary = orders.reduce((old, curr) => {
    const price = curr.product.price * curr.product.chooseAmount;
    return old + price;
  }, 0);

  const [, drop] = useDrop(() => ({
    accept: 'product',
    drop: (value: any) => {
      dragProduct({
        userId: props.id,
        product: {
          availableProductValue: value.availableProductValue,
          id: value.id,
          name: value.name,
          chooseAmount: value.selected,
          price: value.price,
        },
      });
    },
  }));
  return (
    <Paper
      ref={drop}
      sx={{
        width: fullWidth ? '100%' : '178px',
        minWidth: fullWidth ? '100%' : '178px',
        position: 'relative',
        border: (theme) => `2px dashed ${theme.palette.secondary.main}`,
      }}
    >
      <Box sx={{ backgroundColor: 'secondary.main', color: 'secondary.contrastText', padding: 1 }}>{props.name}</Box>
      <Box>
        <List>
          {orders.map((order) => (
            <Box key={order.product.id}>
              <ListItem dense>
                <ListItemText primary={order.product.name} secondary={`${order.product.chooseAmount}szt.`} />
                <IconButton size="small" onClick={() => removeProduct(order)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
              <Box sx={{ margin: '-10px 0px 0px 6px' }}>
                <IconButton
                  size="small"
                  onClick={() => (addOneMoreItem ? addOneMoreItem(order) : null)}
                  disabled={
                    (null != props.showBalance && props.showBalance < order.product.price) ||
                    order.product.availableProductValue
                  }
                >
                  <AddIcon />
                </IconButton>
                <IconButton size="small" onClick={() => (removeOneItem ? removeOneItem(order) : null)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          {orders.length === 0 && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <AddShoppingCartIcon />
              <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>
                <FormattedMessage id="COMPONENT.CART.EMPTY" />
              </Typography>
            </Box>
          )}
        </List>
        {orders.length > 0 && (
          <Typography sx={{ textAlign: 'center', paddingY: 1 }}>
            <FormattedMessage id="COMPONENT.CART.PRICE" values={{ summary: summary.toFixed(2) }} />
          </Typography>
        )}
        {orders.length > 0 && !disabledDetails && (
          <Tooltip
            title={
              <TextField
                value={details || ''}
                onChange={(event) => {
                  if (setDetails) setDetails((old) => ({ ...old, [props.id]: event.target.value }));
                }}
                multiline
                minRows={4}
                maxRows={4}
              />
            }
          >
            <IconButton size="small" style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <ChatBubbleIcon color="secondary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Paper>
  );
};

export default Cart;
