import React from 'react';
import {
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Box,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import fileDownload from 'js-file-download';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { BUDGETS } from 'core/Query';
import { useDrawerNotifications } from 'context/DrawerNotifications/DrawerNotifications';
import Filters from 'components/Admin/OrderingPlatform/ProductsWarehouseAvailability/Filters/Filters';
import { Filter } from 'utils/requestParameters';
import { makeGetDownloadBudgetRequest, makeGetSessionBudgetRequest } from 'core/services/sessionBudgets';
import { BudgetList } from 'types/Butget';

const AdminBudgets = () => {
  const { reloadAdminNotifications } = useDrawerNotifications();
  const [page, setPage] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const budgetQuery = useQuery([BUDGETS, page, filters], () => makeGetSessionBudgetRequest(page, filters), {
    select(response): Paginate<BudgetList> {
      return response.data;
    },
    onSuccess(data: Paginate<BudgetList>) {
      reloadAdminNotifications();
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
  });
  const { data, pages } = useCacheQuery({ data: budgetQuery.data?.data, total: budgetQuery.data?.total });
  const downloadReportMutation = useMutation(makeGetDownloadBudgetRequest, {
    onSuccess(response) {
      fileDownload(response.data, 'report.xlsx');
    },
  });
  return (
    <Grid container spacing={2}>
      {(budgetQuery.isLoading || budgetQuery.isRefetching) && <FullPageLoader />}
      <PageHeaderNoSort>
        <FormattedMessage id="BUDGET.TITLE" />
      </PageHeaderNoSort>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            color="secondary"
            disabled={downloadReportMutation.isLoading}
            variant="contained"
            onClick={() => downloadReportMutation.mutate()}
          >
            <FormattedMessage id="BUDGET.DOWNLOAD" />
          </Button>
        </Box>
      </Grid>

      <Filters handleChangeFilters={setFilters} />

      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ACTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.ONLY_KAM" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.BUDGET" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.BUDGET_USED" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ORDER_SESSION.TABLE.BUDGET_LEFT" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={Math.random()}>
                    <TableCell>{single.name}</TableCell>
                    <TableCell>{single.kam}</TableCell>
                    <TableCell>{null != single.budget ? single.budget : '-'}</TableCell>
                    <TableCell>{null != single.budgetUsed ? single.budgetUsed : '-'}</TableCell>
                    <TableCell>{null != single.budgetLeft ? single.budgetLeft : '-'}</TableCell>
                  </TableRow>
                ))}
                {budgetQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="KAM_MT.ORDERS.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
    </Grid>
  );
};

export default AdminBudgets;
