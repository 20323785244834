import React from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import Filters from 'components/Admin/OrderingPlatform/ProductsWarehouseAvailability/Filters/Filters';
import {
  makeGetActionsWarehouseAvailabilityRequest,
  makeGetDownloadActionsWarehouseAvailabilityRequest,
} from 'core/services/warehouse';
import { ACTIONS_WAREHOUSE_AVAILABILITY } from 'core/Query';
import { Filter } from 'utils/requestParameters';
import fileDownload from 'js-file-download';

const ActionsWarehouseAvailability = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = React.useState<Filter[]>([]);

  const orderQuery = useQuery(
    [ACTIONS_WAREHOUSE_AVAILABILITY, filters],
    () => makeGetActionsWarehouseAvailabilityRequest(filters),
    {
      select(response) {
        const data = response.data;
        data.sort((a: any, b: any) => (a.totalAmount < b.totalAmount ? 1 : -1));

        return data;
      },
      onError(error) {
        enqueueSnackbar(intl.formatMessage({ id: 'ACTIONS_WAREHOUSE_AVAILABILITY.FETCH.ERROR' }), {
          variant: 'error',
        });
      },
    },
  );

  const mutationDownload = useMutation(makeGetDownloadActionsWarehouseAvailabilityRequest, {
    onSuccess(response) {
      const today = new Date();
      fileDownload(response.data, 'magazyn_produktow_akcje' + today.toLocaleString() + '.xlsx');
    },
  });

  return (
    <Grid container spacing={2}>
      {(orderQuery.isLoading || orderQuery.isRefetching) && <FullPageLoader />}

      <PageHeader
        sortValue={'totalAmount'}
        onChangeValue={() => {
          return;
        }}
        sortItems={[
          { value: 'totalAmount', name: <FormattedMessage id="SORT_BY.WAREHOUSE_AVAILABILITY.TABLE.ORIGINAL_STATE" /> },
        ]}
      >
        <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TITLE" />
      </PageHeader>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button
            color="secondary"
            disabled={mutationDownload.isLoading}
            variant="contained"
            onClick={() => mutationDownload.mutate(undefined)}
          >
            <FormattedMessage id="DOWNLOAD.WAREHOUSE_DATA_SHEET" />
          </Button>
        </Box>
      </Grid>

      <Filters handleChangeFilters={setFilters} />

      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.PRODUCT_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.ACTION_NAME" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.ORIGINAL_STATE" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.USED" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.LEFT" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderQuery.data?.map((single: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell>{single.product.name}</TableCell>
                    <TableCell>{single.action.name}</TableCell>
                    <TableCell>{single.totalAmount}</TableCell>
                    <TableCell>{single.currentAmountUsage}</TableCell>
                    <TableCell>{single.availableAmount}</TableCell>
                  </TableRow>
                ))}
                {orderQuery.isFetched && orderQuery.data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <FormattedMessage id="ACTIONS_WAREHOUSE_AVAILABILITY.TABLE.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ActionsWarehouseAvailability;
